// extracted by mini-css-extract-plugin
export var animation = "landingpage-module--animation--Vg9tD";
export var appanimations = "landingpage-module--appanimations--D9c+w";
export var border = "landingpage-module--border--DF5E0";
export var bordergrow = "landingpage-module--bordergrow--ZmukK";
export var browser = "landingpage-module--browser--KwSFb";
export var casestudy = "landingpage-module--casestudy--aGrhL";
export var colorbg1 = "landingpage-module--colorbg1--lEh+Y";
export var colorbg2 = "landingpage-module--colorbg2--q3BFr";
export var commentsvg = "landingpage-module--commentsvg--nFnEA";
export var contenttext = "landingpage-module--contenttext--bhfcY";
export var dapphero = "landingpage-module--dapphero--fLfrb";
export var dfeatureupper = "landingpage-module--dfeatureupper--w2x5N";
export var dlanding = "landingpage-module--dlanding--aF4UF";
export var dlandingheroimage = "landingpage-module--dlandingheroimage--amFd5";
export var emailauto = "landingpage-module--emailauto--Wx2Go";
export var emailimage = "landingpage-module--emailimage--OwE67";
export var empty = "landingpage-module--empty--COBI1";
export var evencontent = "landingpage-module--evencontent---94Ty";
export var evenimage = "landingpage-module--evenimage--HTbNN";
export var evenitem = "landingpage-module--evenitem--OA3nM";
export var fast = "landingpage-module--fast--hC9Ie";
export var feature = "landingpage-module--feature--z55un";
export var featureselected = "landingpage-module--featureselected--UcWax";
export var freecourse = "landingpage-module--freecourse--D1A-K";
export var heroicons = "landingpage-module--heroicons--SxXQs";
export var howcontainer = "landingpage-module--howcontainer--toIgr";
export var howinner = "landingpage-module--howinner--zkW1D";
export var icons = "landingpage-module--icons--0xxfv";
export var inner = "landingpage-module--inner--ZZWfz";
export var landingThree = "landingpage-module--landingThree--Zdcm4";
export var landinghero = "landingpage-module--landinghero--iGSFU";
export var leadmagnet = "landingpage-module--leadmagnet--JEB1l";
export var leadmagnetimg = "landingpage-module--leadmagnetimg--PPy2u";
export var loadingform = "landingpage-module--loadingform--3zp05";
export var lottie = "landingpage-module--lottie--l8mmO";
export var mblock = "landingpage-module--mblock--oy6ob";
export var mfeatures = "landingpage-module--mfeatures--oEtgp";
export var mfeatureupper = "landingpage-module--mfeatureupper--1ZOhP";
export var mlandingheroimage = "landingpage-module--mlandingheroimage--dN1ua";
export var mtotal = "landingpage-module--mtotal--+yWjF";
export var oddcontent = "landingpage-module--oddcontent--e4Wty";
export var oddimage = "landingpage-module--oddimage--1lPGB";
export var odditem = "landingpage-module--odditem--bf6Qx";
export var paragraph = "landingpage-module--paragraph--RnYJB";
export var parallaxsnap = "landingpage-module--parallaxsnap--LYQMD";
export var placeholder = "landingpage-module--placeholder--GUGKA";
export var products = "landingpage-module--products--MDOqp";
export var questionimg = "landingpage-module--questionimg--TD5ot";
export var reactanimation = "landingpage-module--reactanimation--G1vB-";
export var referralinput = "landingpage-module--referralinput--5StnO";
export var refform = "landingpage-module--refform--dz5yL";
export var secondfeature = "landingpage-module--secondfeature--f4RHC";
export var sectionanimation = "landingpage-module--sectionanimation--AvLfa";
export var serveranimation = "landingpage-module--serveranimation--o84wL";
export var slow = "landingpage-module--slow--eg6j5";
export var slowsvg = "landingpage-module--slowsvg--BtW-z";
export var smile = "landingpage-module--smile--BtH7h";
export var specificuser = "landingpage-module--specificuser--rrj-A";
export var submittedform = "landingpage-module--submittedform--hE6EP";
export var svganim = "landingpage-module--svganim--tbtkB";
export var testimonial1 = "landingpage-module--testimonial1--U89W1";
export var visuals = "landingpage-module--visuals--YMYp4";